
import { createStore as reduxCreateStore } from "redux"


const reducer = (state, action) => {

    if (action.type === `ADD_FILTER` || action.type === `REMOVE_FILTER`) {

        const { carrierKey, item, value } = action.payload;

        const filters = state.filters || {};


        if (!filters[carrierKey][item.type]) filters[carrierKey][item.type] = {}



        if (item.type == "CHECKBOX") {
            if (!filters[carrierKey][item.type][item.attribute]) filters[carrierKey][item.type][item.attribute] = new Set()

            const currentSet: Set<String> = filters[carrierKey][item.type][item.attribute];

            if (action.type === `ADD_FILTER`) {
                currentSet.add(value);
            } else {
                currentSet.delete(value);
            }

            filters[carrierKey][item.type][item.attribute] = currentSet;
            return {
                ...state,
                ...{ filters }
            }
        } else if (item.type == "MIN_MAX_SLIDER") {
            filters[carrierKey][item.type][item.attribute] = {
                min: value[0],
                max: value[1],
            }

            return {
                ...state,
                ...{ filters }
            }
        }
    }

    if (action.type == "SORT") {
        return {
            ...state,
            ...{ sort: action.payload }
        }
    }

    if (action.type == "FETCH_QUOTES") {

    }


    return state
}

const initialState = (siteMetadata) => ({
    agentMetadata: siteMetadata?.site?.siteMetadata?.agent,
    facets: [],
    results: {},
    sort: {
        by: "Premium",
        order: "asc",
    },
    filters: {
        "ALL": {},
        "MARKETPLACE": {},
        "NATIONAL_GENERAL": {},
        "ONE_SHARE": {},
        "UNITED_HEALTHCARE": {}
    }
})

const createStore = (siteMetadata) => reduxCreateStore(reducer, initialState(siteMetadata))
export default createStore