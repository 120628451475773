import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Provider } from 'react-redux';

import createStore from "./src/state/createStore"
import "antd/dist/antd.css";
import "./src/styles/antd/global.scss"
import "./src/styles/global.scss"
import "./src/styles/typography/global.scss"
import "./src/styles/headings/global.scss"
import "./src/styles/nav/global.scss"
import "./src/styles/antd/global.scss"

export const wrapRootElement = ({ element }) => {
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                        site {
                            siteMetadata {

                                agent {
                                    assets {
                                        image
                                    },
                                    id,
                                    name,
                                    phone,
                                    whatsapp,
                                    email,
                                    website,
                                    title,
                                    imageSrc,
                                    calendly,
                                    bio
                                }
                            }
                        }
                }`}
            render={(data) => {

                return <Provider store={createStore(data)}>{element}</Provider>;
            }}
        />
    );
};


